import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import TrendCard from "../components/trend-card"
import SEO from "../components/seo"

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allContentfulPostsVangtry.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
    const nextPage = "/blog/" + (currentPage + 1).toString()
    return (
      <Layout>
        <SEO title="Blog" />
        <div className="container page-wrapper">
          <div>
            <div className="special_description_content">
              <div className="section_heading">
                <h2 className="title-text">Nuestro Blog</h2>
                <div className={`mb-5 underline_shape`}></div>
              </div>

              <p className="paragpah-text">
                Un espacio para enterarse de avances, noticias, consejos y
                opiniones sobre el mundo de la tecnologia.
              </p>
            </div>
          </div>

          <p>
            <Link to="/">Regresar al inicio</Link>
          </p>
          <div className="row">
            {posts.map(({ node }) => {
              const title = node.title || node.slug
              return (
                <div
                  key={node.slug}
                  className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3"
                >
                  <TrendCard
                    title={title}
                    featuredImage={node.featuredImage.fluid}
                    imgTitle={title}
                    linkPost={`/blog/${node.slug}/`}
                    author="Equipo vangTry"
                  >
                    {title}
                  </TrendCard>
                </div>
              )
            })}
          </div>
        </div>
        <ul
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            listStyle: "none",
            padding: 0,
            marginTop: "10px",
          }}
        >
          {!isFirst && (
            <Link to={prevPage} rel="prev">
              ← Página anterior
            </Link>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <li
              key={`pagination-number${i + 1}`}
              style={{
                margin: 0,
                background: i + 1 === currentPage ? "#1b232d" : "",
                borderRadius: "44vh",
                padding: 9,
              }}
            >
              <Link
                to={`/blog/${i === 0 ? "" : i + 1}`}
                style={{
                  textDecoration: "none",
                  color: i + 1 === currentPage ? "#f7ce01" : "",
                  background: i + 1 === currentPage ? "#1b232d" : "",
                }}
              >
                {i + 1}
              </Link>
            </li>
          ))}
          {!isLast && (
            <Link to={nextPage} rel="next">
              Página siguiente →
            </Link>
          )}
        </ul>
      </Layout>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allContentfulPostsVangtry(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: publishedDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          publishedDate(formatString: "MMM YYYY")
          featuredImage {
            fluid(maxWidth: 750) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
