import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import trendCardStyles from "../styles/scss/_trendcard.module.scss"
import { Link } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Img from "gatsby-image"

const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    paddingBottom: 10,
    maxWidth: "100%",
    height: "100%",
  },

  media: {
    width: "100%",
    height: "15vh !important",
  },
  actionsbtn: {
    float: "right",
  },
})

export default function TrendCard(props) {
  const classes = useStyles()
  const { title, linkPost, imgTitle, author, featuredImage } = props

  return (
    <Card className={classes.root} variant="outlined">
      <Img
        className={(classes.media, `d-none d-md-block`)}
        fluid={featuredImage}
        alt={imgTitle}
      />

      <CardContent>
        <div className={`${trendCardStyles.texts_content}`}>
          <Typography
            gutterBottom
            className={`title-text ${trendCardStyles.trendcard_title}`}
            component="h5"
          >
            {title}
          </Typography>
          <Typography
            gutterBottom
            className={`paragraph-text ${trendCardStyles.trendcard_paragraph} 
            
            `}
            component="p"
          >
            {author}
          </Typography>
        </div>

        <div className={classes.actionsbtn}>
          <Link to={linkPost} className={`pl-2 paragraph-text`}>
            Leer más...
          </Link>
        </div>
      </CardContent>
    </Card>
  )
}
